/* import component css */
@use "loginPage.scss";

html {
  height: 100%;
  overflow-y: auto !important;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
}

.cs-main-container-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.mainWrapper {
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 700px;
  width: 100%;
}

.portal {
  .portal-greeting-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    .portal-greeting-content {
      display: flex;
      flex-direction: column;
      width: 50%;

      h2 {
        margin-bottom: 0px;
        padding: 0;
      }

      p {
        font-size: 12px;
        margin-top: 5px;
        padding: 0;
      }
    }
  }

  .portal-main-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    .portal-main-content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: space-evenly;
      margin-top: 48px;
      margin-bottom: 48px;
      width: 100%;
      max-width: 800px;
      align-items: center;

      @media screen and (max-width: 800px) {
        align-items: center;
        flex-direction: column;
        margin-top: 50px;
        width: 90%;
      }

      .portal-column {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;


        .portal-column-item {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: 15px 0;

          &.border {
            padding: 30px 15px;
            border: 2px solid #4c338c;
            border-radius: 6px;
          }

        }
      }
    }

    .portal-furtherInformation-firstRow {
      p, a {
        display: inline;
      }
    }
  }
}

.mainPage {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  margin-top: 50px;

  &.no-mt {
    margin-top: 0;
  }

  form {
    margin-bottom: 15px;
  }
}

/*
 New Styles after Redesign
 */

.portal_routingpage_wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;

  @media only screen and (max-width: 750px) {
    align-items: unset;
    display: unset;
    flex-direction: unset;
    justify-content: unset;
  }
}